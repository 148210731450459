import React, { forwardRef, useContext } from "react";
import { Tooltip as Tippy } from "react-tippy";
import { ThemeContext } from "styled-components";
import type {
  Position as tPosition,
  Animation as tAnimation,
  Trigger as tTrigger,
  Size as tSize,
  Tooltip as tTooltip,
} from "react-tippy";
import "react-tippy/dist/tippy.css";

type tProps = {
  disabled?: boolean;
  destroyOnHide?: boolean;
  title?: string;
  position?: tPosition;
  animation?: tAnimation;
  interactive?: boolean;
  trigger?: tTrigger;
  showArrow?: boolean;
  titleComponent?: any;
  size?: tSize;
  children?: any;
};
const Tooltip = forwardRef<tTooltip, tProps>((props: tProps, ref) => {
  const theme = useContext(ThemeContext);
  return (
    <Tippy
      disabled={props.disabled}
      unmountHTMLWhenHide={props.destroyOnHide}
      ref={ref}
      title={props.title}
      position={props.position}
      animation={props.animation}
      theme={theme.__name__}
      size={props.size}
      html={props.titleComponent}
      trigger={props.trigger}
      interactive={props.interactive}
      arrow={props.showArrow}
    >
      {props.children}
    </Tippy>
  );
});
Tooltip.displayName = "Tooltip";
Tooltip.defaultProps = {
  interactive: false,
  trigger: "mouseenter",
  titleComponent: null,
  size: "small",
  position: "top",
  showArrow: false,
};

export default Tooltip;
